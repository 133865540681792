import { NavLink } from "react-router-dom";

const SideBar = () => {
  return (
    <div className="sidebar" id="sidebar">
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <ul>
            <li className="menu-title">
              <span>Main</span>
            </li>
            <li className="submenu">
              <a href="!#">
                <i className="la la-dashboard" /> <span> Dashboards</span> <span className="menu-arrow" />
              </a>
              <ul style={{ display: "none" }}>
                <li>
                  <NavLink to="/dashboards/general">General Dashboard</NavLink>
                </li>
                <li>
                  <NavLink to="/dashboards/leaves">Leaves Dashboard</NavLink>
                </li>
                <li>
                  <NavLink to="/dashboards/terminations">Terminations Dashboard</NavLink>
                </li>
              </ul>
            </li>

            <li className="menu-title">
              <span>Employee Management</span>
            </li>
            <li>
              <NavLink to="/employees">
                <i className="la la-users" /> <span> all Employees</span>{" "}
              </NavLink>
            </li>
            <li className="submenu">
              <a href="!#">
                <i className="la la-gear" /> <span> Manage</span> <span className="menu-arrow" />
              </a>
              <ul style={{ display: "none" }}>
                <li>
                  <NavLink to="/branches">Branches</NavLink>
                </li>
                {/* <li>
                  <NavLink to="/departments">Departments</NavLink>
                </li> */}
                <li>
                  <NavLink to="/designations">Designations</NavLink>
                </li>
                <li>
                  <NavLink to="/configurations"> Configurations </NavLink>
                </li>
              </ul>
            </li>
            <li className="submenu">
              <a href="!#">
                <i className="la la-chart-line" /> <span> Performance</span> <span className="menu-arrow" />
              </a>
              <ul style={{ display: "none" }}>
                <li>
                  <NavLink to="/kras">KRAs</NavLink>
                </li>
                <li>
                  <NavLink to="/evaluationCriteria">Evaluation Criteria</NavLink>
                </li>
                <li>
                  <NavLink to="/appraisal/template/new">New Appraisal Template</NavLink>
                </li>
                <li>
                  <NavLink to="/appraisal/template/list">Appraisal Templates</NavLink>
                </li>
                <li>
                  <NavLink to="/appraisal/cycles"> Appraisal Cycle </NavLink>
                </li>
              </ul>
            </li>
            <li className="menu-title">
              <span>Payroll</span>
            </li>
            <li>
              <NavLink to="/payrolls/filter">
                {/* <NavLink to="/payrolls/preview"> */}
                <i className="la la-list" /> <span> Payroll Preview</span>{" "}
              </NavLink>
            </li>
            <li>
              <NavLink to="/payrolls">
                <i className="la la-money" /> <span> Payroll Report</span>{" "}
              </NavLink>
            </li>
            <li className="menu-title">
              <span>Reports</span>
            </li>
            {/* <li>
              <NavLink to="/reports/Attendance">
                <span>Attendance</span>
              </NavLink>
            </li> */}
            <li className="submenu">
              <a href="#">
                <span> Leaves </span> <span className="menu-arrow" />
              </a>
              <ul style={{ display: "none" }}>
                <li>
                  <NavLink to="/reports/leaves/leaveBalances">
                    <span>Leave Balances</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/reports/leaves/summary">
                    <span>Leave Summary</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/reports/annual-leave">
                    <span>Annual Leave</span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="submenu">
              <a href="#">
                <span> Performance </span> <span className="menu-arrow" />
              </a>
              <ul style={{ display: "none" }}>
                <li>
                  <NavLink to="/reports/template/utilization">
                    <span>Template Utilization</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/reports/appraisals/yearly">
                    <span>Employee Appraisals</span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to="/reports/allowances">
                <span>Allowances</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/reports/deductions">
                <span>Deductions</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/reports/overtimes">
                <span>Overtimes</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/reports/terminations">
                <span>Terminations</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/reports/audits">
                <span>System Audits</span>
              </NavLink>
            </li>

            <li className="menu-title">
              <span>Recruitment</span>
            </li>
            <li>
              <NavLink to="/recruitment/offers">
                <i className="la la-briefcase" /> <span>Job Offers</span>
              </NavLink>
            </li>
            <li className="menu-title">
              <span>Administration</span>
            </li>
            <li className="submenu">
              <a href="#">
                <i className="la la-users" /> <span> User Management </span> <span className="menu-arrow" />
              </a>
              <ul style={{ display: "none" }}>
                <li>
                  <NavLink to="/users"> Users </NavLink>
                </li>
                <li>
                  <NavLink to="/roles"> Roles </NavLink>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
