import { ENDPIONTS, httpService } from "@api";
import { SalaryScaleRequest, SalaryScaleResponse } from "@models";
import { useState } from "react";
import { useForm } from "react-hook-form";

interface Props {
  callback?: () => void;
  salaryScale?: SalaryScaleResponse;
}

const AddSalaryScale = ({ callback, salaryScale }: Props) => {
  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: SalaryScaleRequest, e: any) => {
    setIsLoading(true);

    let scale: Partial<SalaryScaleRequest> = {
        description: data.description,
        minAmount: data.minAmount,
        maxAmount: data.maxAmount,
    };

    if (salaryScale?.id) {
      await httpService(ENDPIONTS.salaryScales).update(salaryScale.id, scale);
    } else {
      await httpService(ENDPIONTS.salaryScales).post(scale);
    }

    callback?.();
    setIsLoading(false);
    e.target.reset();
  };

  return (
    <>
      <h4>{salaryScale ? "Edit" : "Add"} Salary Scale</h4>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Scale</label>
              <div className="col-12">
                <input
                  type="text"
                  defaultValue={salaryScale?.description ?? ""}
                  className="form-control"
                  name="description"
                  ref={register({ required: true })}
                />
              </div>
              <span className="text-danger">{errors.description && <span>This field is required</span>}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Minimum Amount</label>
              <div className="col-12">
                <input
                  type="number"
                  step='0.01'
                  defaultValue={salaryScale?.minAmount ?? ""}
                  className="form-control"
                  name="minAmount"
                  ref={register({ required: true })}
                />
              </div>
              <span className="text-danger">{errors.minAmount && <span>This field is required</span>}</span>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>Maximum Amount</label>
              <div className="col-12">
                <input
                  type="text"
                  defaultValue={salaryScale?.maxAmount ?? ""}
                  className="form-control"
                  name="maxAmount"
                  ref={register({ required: true })}
                />
              </div>
              <span className="text-danger">{errors.maxAmount && <span>This field is required</span>}</span>
            </div>
          </div>
        </div>
        <input
          type="submit"
          name="time"
          className="btn btn-primary"
          disabled={isLoading}
          value={isLoading ? "Please wait..." : salaryScale ? "Update" : "Register"}
        />
      </form>
    </>
  );
};

export default AddSalaryScale;
