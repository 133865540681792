import { ENDPIONTS, PaginatedResult } from "@api";
import { AddSalaryScale } from "@components";
import { useFetch } from "@hooks";
import { SalaryScaleResponse } from "@models";
import { Action, ComplexHeader, Table } from "@shared";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const ListSalaryScale = () => {
  const MySwal = withReactContent(Swal);

  const [reload, setReload] = useState<boolean | undefined>(undefined);

  const fetchSalaryScales = useFetch<PaginatedResult<SalaryScaleResponse>>(
    { endPoint: ENDPIONTS.salaryScales, reload: reload },
    new PaginatedResult<SalaryScaleResponse>()
  );

  useEffect(() => {}, [fetchSalaryScales?.isFetching]);

  const headers: ComplexHeader[] = [
    { key: "description", title: "Scale" },
    { key: "minAmount", title: "min" },
    { key: "maxAmount", title: "max" },
    { key: "createdAt", title: "@", format: "date" },
    { key: "createdBy", title: "By" },
  ];

  const actions: Action[] = [
    {
      key: "",
      actionType: "badge",
      click: (a: SalaryScaleResponse) => {
        onButtonClick(null, a);
      },
      title: "Edit",
      color: "dark",
    },
  ];

  const onButtonClick = (e: any, a?: SalaryScaleResponse) => {
    e !== null && e.preventDefault();

    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 600,
      html: (
        <AddSalaryScale salaryScale={a} callback={() => setReload((prev) => (prev === undefined ? true : !prev))} />
      ),
    });
  };
  return (
    <div className="col-5 d-flex">
      <div className="card profile-box flex-fill">
        <div className="card-body">
          <h3 className="card-title">
            Salary Scales
            <a
              href="!#"
              onClick={(e) => onButtonClick(e)}
              className="edit-icon"
              data-bs-toggle="modal"
              data-bs-target="#personal_info_modal"
            >
              <i className="fa fa-plus" />
            </a>
          </h3>
          <Table
            actions={actions}
            class="table table-striped table-hover mb-0"
            data={fetchSalaryScales?.data ?? []}
            headers={headers}
            isFetchingPage={fetchSalaryScales.isFetching}
            showCounter
            // onPageChange={handlePageChange}
            paginationClass="row mt-3"
          />
        </div>
      </div>
    </div>
  );
};

export default ListSalaryScale;
