import { Branch, Department, Designation, LeaveBalance, PayrollGroup, SubBranch, User } from "@models";
import { BaseModel } from "./baseModel";
import { Shift } from "./shift";

export class Employee extends BaseModel {
    name: string;
    employeeId: string | null;
    typeOfContract: string;
    phone: string;
    birthDate: string;
    country: string | null;
    address: string | null;
    gender: string | null;
    passportNo: string | null;
    passportExpiry: string | null;
    nationality: string | null;
    religion: string | null;
    maritalStatus: string | null;
    children: number | null;
    ageGroup: string;
    dateOfEmployment: string | null;
    email: string | null;
    jobGrade: string;
    levelOfEducation: string | null;
    sourceOfIncome: string | null;
    contractStatus: boolean;
    accountNumber: string | null;
    salary: number;
    imgUrl: string | null;
    payrollGroupID: number;
    payrollGroup?: PayrollGroup;
    departmentId: number;
    department?: Department;
    designationId: number;
    designation?: Designation;
    branchId: number;
    branch?: Branch;
    userId: number | null;
    user?: User;

    shiftId?: number | null;
    shift?: Shift

    subBranchId?: number | null;
    subBranch?: SubBranch

    lineManagerId?: number;

    leaveBalance: LeaveBalance
    type:string
}