import { ENDPIONTS, httpService, PaginatedResult } from "@api";
import { useFetch } from "@hooks";
import { Employee, SalaryScaleResponse } from "@models";
import { DateHelpers } from "@shared";
import { useEmployeeStore } from "@stores";
import { EmployeePersonalInfo } from "@viewModels";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

interface Props {
  callback?: () => void;
  employee?: Employee;
}

const ChangeSalary: React.FC<Props> = ({ callback, employee }) => {
  const calculateSalary = useEmployeeStore((s) => s.calculateSalary);
  const calculateIfSalaryIsDeductible = useEmployeeStore((s) => s.calculateIfSalaryIsDeductible);

  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedScale, setSelectedScale] = useState<SalaryScaleResponse>();

  const fetchSalaryScales = useFetch<PaginatedResult<SalaryScaleResponse>>(
    { endPoint: ENDPIONTS.salaryScales, ignorePagination: true },
    new PaginatedResult<SalaryScaleResponse>()
  );

  useEffect(() => {
    if (!fetchSalaryScales.isFetching) {
      if (employee?.jobGrade) onJobGradeChange(employee.jobGrade);
    }
  }, [fetchSalaryScales.isFetching, employee?.jobGrade]);

  const onSubmit = async (data: any, e: any) => {
    if (!employee?.id) return;

    setIsLoading(true);

    let emp = {
      id: employee?.id,
      scale: data.jobGrade,
      amount: data.amount,
      description: data.description,
    };

    await httpService(ENDPIONTS.changeSalary).update(employee.id, emp);

    calculateSalary(employee?.id);
    await calculateIfSalaryIsDeductible(employee?.id);

    callback?.();
    setIsLoading(false);
    e.target.reset();
  };

  const onJobGradeChange = (scale: string) => {
    let selected = fetchSalaryScales?.data?.items.find((x) => x.description === scale);
    setSelectedScale(selected);
  };

  return (
    <>
      <h4>Change Employee Salary</h4>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Job Grade</label>
              <select
                className="form-control select"
                name="jobGrade"
                ref={register({ required: true })}
                onChange={(e) => onJobGradeChange(e.target.value)}
              >
                <option></option>
                {fetchSalaryScales?.data?.items.map((g, idx) => {
                  return (
                    <option selected={employee?.jobGrade === g.description} value={g.description} key={idx}>
                      {g.description} - {g.minAmount} - {g.maxAmount}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>Amount</label>
              <div className="col-12">
                <input
                  type="number"
                  defaultValue={employee?.salary ?? ""}
                  className="form-control"
                  name="amount"
                  ref={register({ required: true })}
                  min={selectedScale?.minAmount ?? 0}
                  max={selectedScale?.maxAmount ?? 0}
                  step="0.01"
                />
              </div>
              <span className="text-danger">{errors.amount && <span>This field is required</span>}</span>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <div className="form-group">
              <label htmlFor="description">Remarks</label>
              <textarea
                className="form-control mb-4"
                name="description"
                id="description"
                aria-label="With textarea"
                ref={register({ required: true })}
              ></textarea>
              <span className="text-danger">{errors.description && <span>This field is required</span>}</span>
            </div>
          </div>
        </div>

        <input
          type="submit"
          name="time"
          className="btn btn-primary"
          disabled={isLoading}
          value={isLoading ? "Please wait..." : "Register"}
        />
      </form>
    </>
  );
};

export default ChangeSalary;
